import newlogo from "./images/newlogo.png";
import LoginIcon from '@mui/icons-material/Login';
import MobileMenu from "./MobileMenu";

export default function Header({width, loginStatus, email, setLoginPopup, setToggle, signOut}){

    const userClick = () => {

    }

    return(
      <div className="header-wrapper">
        <header>
            <div className="topbar d-flex align-items-center">
                <nav className="navbar navbar-expand gap-3">
                    <div className="topbar-logo-header d-none d-lg-flex">
                        <div className="">
                            <h4 className="logo-text">
                              <a href="/">
                                <img src={newlogo} className="newlogo" alt=""/>  
                              </a>
                            </h4>
                        </div>
                    </div>
                    <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>

           { width > 768 ? 
            <div className="primary-menu">
               <nav className="navbar navbar-expand-lg align-items-center">
                  <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header border-bottom">
                        <div className="d-flex align-items-center">
                            <div className="">
                               { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                            </div>
                            <div className="">
                                <h4 className="logo-text">
                                  <a href="/">
                                   <img src={newlogo} className="newlogo" alt=""/>  
                                  </a>
                                </h4>
                            </div>
                        </div>
                      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                      <ul className="navbar-nav align-items-center flex-grow-1">
                        <li className="nav-item">
                            <a className="nav-link dropdown-toggle-nocaret" href="/">
                                <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                </div>
                                <div className="menu-title d-flex align-items-center">Home</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                <div className="parent-icon"><i className='bx bx-dollar'></i>
                                </div>
                                <div className="menu-title d-flex align-items-center">Pricing</div>
                            </a>                                
                          </li>
                          <li className="nav-item">
                            <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                </div>
                                <div className="menu-title d-flex align-items-center">Help</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                <div className="parent-icon"><i className='bx bxs-note'></i>
                                </div>
                                <div className="menu-title d-flex align-items-center">Blog</div>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                                <div className="parent-icon"><i className='bx bx-code'></i>
                                </div>
                                <div className="menu-title d-flex align-items-center">API</div>
                            </a>
                          </li>
                      </ul>
                    </div>
                  </div>
              </nav>
        </div>
          :
          ''
         }

        { loginStatus === 'success' ?
                   <>
                   { width < 768 ? 
                    <div className="user-box dropdown px-3">
                        <a className="d-flex align-items-center nav-link gap-3" href="/">
                          <img src={newlogo} className="newlogo" alt=""/>
                        </a>
                    </div>
                     :
                    <div className="user-box dropdown px-3">
                        <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="user-info">
                               <p className="user-name mb-0">{email}</p>  
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={userClick}><i class="bx bx-user fs-5"></i><span>Account</span></a>
                            </li>
                            <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={signOut}><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                            </li>
                        </ul>
                    </div>
                   }
                    </>
                    :
                    <>
                    { width < 768 ? 
                    <div className="user-box dropdown px-3">
                        <a className="d-flex align-items-center nav-link gap-3" href="/">
                          <img src={newlogo} className="newlogo" alt=""/>
                        </a>
                    </div>
                    : 
                    <div className="user-box px-3">
                        <a className="d-flex align-items-center nav-link gap-3" href="#" role="button">
                            <div className="user-info">
                             <button onClick={() => setLoginPopup(true)}><LoginIcon/> Login</button>
                            </div>
                        </a>
                    </div>
                    }
                    </>
                }

                </nav>
            </div>
        </header>

        { width < 768 ? <MobileMenu logo={newlogo} loginStatus={loginStatus} email={email} /> : ''}

       </div>
    )
}
import "./App.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, logoutUser } from "./slices/authSlice";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Help from './pages/Help';
import Privacy from './pages/Privacy';
import Users from './pages/Users';
import Login from "./pages/Login";
import UnderConstruction from "./components/UnderConstruction";
import Footer from "./components/Footer";
import BackgroundSwitcher from "./components/BackgroundSwitcher";
import LoginPopup from "./components/LoginPopup";
import Header from "./components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWindowSize from "./cutom-hooks/useWindowSize";

function App() {
  const { email, loginStatus, registerStatus } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();
  useEffect(() => {
     dispatch(loadUser(null));
  },[dispatch]);

  const [loginPopup, setLoginPopup] = useState(false);
  const [toggle, setToggle] = useState('switch to login');

  const { width } = useWindowSize();

  const signOut = () => {
    dispatch(logoutUser(null));
    toast.error('Logged out');
  }

  return (
    <>
     <div className="wrapper">
      <Header 
         width={width} 
         loginStatus={loginStatus} 
         email={email} 
         setLoginPopup={setLoginPopup} 
         setToggle={setToggle} 
         signOut={signOut}
      />
      <Router>
        <Routes>
          <Route path="/" element={<Home width={width} loginStatus={loginStatus} setLoginPopup={setLoginPopup}/>} />
          <Route path="/help" element={<Help/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/admin/users" element={<Users/>} />
          <Route path="/login" element={<Login/>} />
        </Routes>
      </Router>
      <Footer/>
      <LoginPopup 
         loginPopup={loginPopup} 
         setLoginPopup={setLoginPopup} 
         toggle={toggle} 
         setToggle={setToggle} 
         loginStatus={loginStatus} 
         email={email} 
         registerStatus={registerStatus}
      />
      <BackgroundSwitcher/>
      <UnderConstruction/> 
      <ToastContainer/> 
     </div>
    </>
  );
}

export default App;

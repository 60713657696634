import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export const Anchors = ({anchors, setAnchorValue, removeAnchor, addLink}) => {
    return (
       <div className='anchor-main'>
        <table className='anchor-table'>
          <tr>
            <th>Anchor Text</th>
            <th>URL</th>
          </tr> 
          { anchors.map((field, index) => (
             <tr>
               <td><input type='text' onChange={(e) => setAnchorValue(index, 'text', e.target.value)}/></td>
               <td><input type='text'onChange={(e) => setAnchorValue(index, 'url', e.target.value)} /></td>
               <td><span style={{cursor: 'pointer'}} onClick={() => removeAnchor(index)}><RemoveCircleIcon/></span></td>
             </tr> 
            ))
          }
        </table>
        <div style={{textAlign: 'right', marginTop: '18px'}}>
          <button className='add_more_link' onClick={addLink}><AddCircleIcon/> Add more link</button>
        </div>
       </div>
    )
}
export const Websites = ({websites, getSelectedWebsites}) => {
   return (
    <>
     { websites.length > 0 ? 
       <table>
        <tr>
          <th>Sln.</th>
          <th>Website</th>
          <th>DR</th>
          <th>Niche</th>
          <th>Price</th>
        </tr>
        {websites.map((item, index) =>
          <tr>
            <td>{index + 1}</td>
            <td>
              <div class="checkbox-wrapper-46">
               <input className="inp-cbx" id={'cbx-' + index} type="checkbox" value={item.name} onChange={(e) => getSelectedWebsites(e.target.value)}/>
               <label className="cbx" for={'cbx-' + index}>
                <span>
                 <svg width="12px" height="10px" viewbox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                 </svg>
                </span>
                <span>{item.name}</span>
               </label>
              </div>
            </td>
            <td>{item.dr}</td>
            <td>{item.niche}</td>
            <td>{item.price} USD</td>
          </tr>
        )}
       </table>
       :
       <p>You do not have any websites.</p> 
    }
    </>
   )
}
export default function MobileMenu({logo, loginStatus, email}) {

   const userClickMobile = () => {

   }

   return(
    <div className="primary-menu">
     <nav className="navbar navbar-expand-lg align-items-center">
       <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
         <div className="offcanvas-header border-bottom">
             <div className="d-flex align-items-center">
                 <div className="">
                     <h4 className="logo-text">
                       <a className="d-flex align-items-center nav-link gap-3" href="/">
                         <img src={logo} className="newlogo" alt=""/>
                       </a>
                     </h4>
                 </div>
             </div>
           <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
         </div>
         <div className="offcanvas-body">
           <ul className="navbar-nav align-items-center flex-grow-1">
             <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="/">
                     <div className="parent-icon"><i className='bx bx-home-alt'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">Home</div>
                 </a>
               </li>
               <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                     <div className="parent-icon"><i className='bx bx-dollar'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">Pricing</div>
                 </a>                                
               </li>
               <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="/help">
                     <div className="parent-icon"><i className='bx bx-question-mark'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">Help</div>
                 </a>
               </li>
               <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                     <div className="parent-icon"><i className='bx bxs-note'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">Blog</div>
                 </a>
               </li>
               <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                     <div className="parent-icon"><i className='bx bx-code'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">API</div>
                 </a>
               </li>
               <li className="nav-item">
                 <a className="nav-link dropdown-toggle-nocaret" href="#" onClick={userClickMobile}>
                     <div className="parent-icon"><i className='bx bx-user'></i>
                     </div>
                     <div className="menu-title d-flex align-items-center">{ loginStatus === 'success' ? email : 'Sign in'}</div>
                 </a>
               </li>
           </ul>
         </div>
       </div>
      </nav>
     </div>
   )
}
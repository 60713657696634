import React, { useEffect, useState } from 'react';
import { Buttons } from './Buttons';
import { Websites } from './Websites';
import './css/MultiStepForm.css'
import { Anchors } from './Anchors';
import writing from '.././images/wait.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function MultiStepForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const steps = 3;

  const [articles, setarticles] = useState(); 
  const [popup_article, setPopupArticle] = useState();
  const handleNext = async () => {
    if(currentStep === 1){
        if(selected_websites.length > 0){
          setCurrentStep((prevStep) => Math.min(prevStep + 1, steps));
        }else{
          alert('Please select at least one website');
        }
    }
    if(currentStep === 2){
        if(anchors[0].text !== '' && anchors[0].url !== ''){
          setCurrentStep((prevStep) => Math.min(prevStep + 1, steps));
          
          try {
            console.log('request start');
            const response = await fetch('https://guestpostbot.com/nodeapi/generate-articles', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ selected_websites: selected_websites.length, anchors: anchors })
            });
            const data = await response.json();
            console.log(data); // Log the API response for now
            setarticles(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }

        }else{
          alert('Please add at least one url');
        }
    }
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handlePublish = (e) => {
    e.preventDefault();
    alert('On progress');
    //setCurrentStep(1); // Reset the form
  };

  const websites = [
    {
      name: 'https://editwishes.com',
      dr: 'test',
      niche: 'test',
      price: 10
    },
    {
      name: 'https://jokeinenglish.com',
      dr: 'test',
      niche: 'test',
      price: 10
    },
    {
      name: 'https://knowthedogs.com',
      dr: 'test',
      niche: 'test',
      price: 10
    },
    {
      name: 'https://groundingmanual.com',
      dr: 'test',
      niche: 'test',
      price: 10
    },
    {
      name: 'https://beardeddragonsdiet.com',
      dr: 'test',
      niche: 'test',
      price: 10
    }
  ];

  const [selected_websites, setSelectedWebsites] = useState([]);

  const getSelectedWebsites = (val) => {
     //console.log(val);
     setSelectedWebsites([...selected_websites, val]);
  }

  const [anchors, setAnchors] = useState([{text: '', url: ''}]);
  const addLink = () => {
    setAnchors([...anchors, {text: '', url: ''}]);
  }
  const removeAnchor = (idx) => {
    //console.log(index);
    if(anchors.length > 1) {
      let new_anchors = anchors.filter((field, index) => idx !== index);
      setAnchors(new_anchors);
    }else{
      alert('At least one url and anchor text is required.');
    }
  }

  const setAnchorValue = (index, type, fieldValue) => {
     let new_anchors = [...anchors];
     let field = new_anchors[index];
     let upadted_field = {}; 
     if(type === 'text'){
         upadted_field = {...field, text: fieldValue};
     }else{
        upadted_field = {...field, url: fieldValue};
     }
     new_anchors[index] = upadted_field;
     setAnchors(new_anchors);
  }

  useEffect(() => {
    console.log(anchors);
  }, [anchors])

  return (
    <div className="form-main">
      <div className="progress-bar-multi">
        {['Select Websites', 'Add  your Link', 'Publish'].map((label, index) => (
          <div className="step" key={index}>
            <div className={`bullet ${currentStep > index + 1 ? 'active' : ''}`}>
              <span>{index + 1}</span>
            </div>
            <p className={currentStep > index ? 'active' : ''}>{label}</p>
          </div>
        ))}
      </div>
      <div className="form-outer">
          <div className={`page ${currentStep === 1 ? 'active' : ''}`}>
            <Websites websites={websites} getSelectedWebsites={getSelectedWebsites}/>
          </div>
          
          <div className={`page ${currentStep === 2 ? 'active' : ''}`}>
            <Anchors 
               anchors={anchors} 
               setAnchorValue={setAnchorValue} 
               removeAnchor={removeAnchor}
               addLink={addLink}  
            />
          </div>

          <div className={`page ${currentStep === 3 ? 'active' : ''}`}>
            { !articles &&
              <>
                <br/><br/>
                <img src={writing} alt='' style={{width: '60px'}}/>
                <br/><br/>
                <p>Generating your articles, this take a while.</p>
              </> 
            }

            { articles &&
              <div className='articles'>
                <h5>{articles.length} Articles have been Auto Generated for Publication:</h5>
                <ul>
                 { articles && articles.map((article, index) => 
                    <li onClick={() => setPopupArticle(article)}>{(index + 1) +'. '+ article.title}</li>  
                   )
                 }
                </ul>
              </div>
            }

            { popup_article && 
                  <div className='popup-article'>
                    <div className='popup-article-content'>
                      <h1 contentEditable dangerouslySetInnerHTML={{ __html: popup_article.title }}></h1>
                      <br></br>
                      <div contentEditable dangerouslySetInnerHTML={{ __html: popup_article.content }}></div>
                      <span className='popup-article-close' onClick={() => setPopupArticle()}><HighlightOffIcon/></span>
                    </div>
                  </div>
              }
          </div>

          <div className="field btns">
            <Buttons
               currentStep={currentStep} 
               handleNext={handleNext}
               handlePrev={handlePrev} 
               handlePublish={handlePublish} 
               steps={steps}
            />
          </div>
      </div>
    </div>
  );
}

export default MultiStepForm;
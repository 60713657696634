import { useEffect, useState } from "react";
import { loginUser } from "../slices/authSlice";
import { useDispatch } from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import wait from "./images/wait.svg";

const LoginPopup = ({loginPopup, setLoginPopup, toggle, setToggle, loginStatus, email, registerStatus}) => {

  const [user, setUser] = useState({
      email: "",
      password: "",
      cnf_password: ""
  });

  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [forgot_pass_email, setForgotPassEmail] = useState('');
  const [forgot_pass_submit, setForgotPassSubmit] = useState(false);
  const [resetPassOTP, setResetPassOTP] = useState('');
  const [resetPassNew, setResetPassNew] = useState('');
  const [resetPassConfirm, setResetPassConfirm] = useState('');
  const [reset_pass_submit, setResetPassSubmit] = useState(false);
  const [resetPassSuccess, setResetPassSuccess] = useState(false);
  const [resetPassFailure, setResetPassFailure] = useState(false);

  const handleSubmit = (e) => {
      e.preventDefault();
  };

  const dispatch = useDispatch();
  const handleSubmitLogin = (e) => {
      e.preventDefault();
      if(user.email === '' && user.password === ''){
        alert('Please enter email and password');
        return;
      }
      console.log(user);
      dispatch(loginUser(user));
  }

  useEffect(() => {
    if(loginStatus === 'success'){
      setLoginPopup(false);
    }
  }, [loginStatus]);

  const signOut = (e) => {
    e.preventDefault(); 
  }
  
  const forgotPassword = () => {
     setForgotPass(true);
     setToggle("");
  }
  const sendOTP = (e) => {
     e.preventDefault();
  
     if(forgot_pass_email === ''){
         alert('Please enter your email');
         return;
     }
     setForgotPassSubmit(true);
     fetch('https://oneclickhuman.com/oneclickprice/forgot_password', {
        mode:'cors', 
        method: 'POST',
        body: JSON.stringify({
          'email' : forgot_pass_email
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        }
      }).then(res => res.json())
        .then((json) => {
                //console.log(json); 
                if(json.status === 'success'){
                     setForgotPassSubmit(false);
                     setForgotPass(false);
                     setResetPass(true);
                }
      });
  }
  const handleResetPassword = (e) => {
     e.preventDefault();
     if(resetPassOTP === ''){
         alert('Please Enter OTP');
         return;
     }  
     if(resetPassNew === ''){
         alert('Please Enter new password');
         return;
     }
     if(resetPassConfirm === ''){
         alert('Please Enter confirm password');
         return;
     }
     if(resetPassNew !== resetPassConfirm){
         alert('New Password and Confirm Password does not match!');
         return;
     }
  
     setResetPassSubmit(true);
     setResetPassFailure(false);
  
        fetch('https://oneclickhuman.com/oneclickprice/reset_password', {
        mode:'cors', 
        method: 'POST',
        body: JSON.stringify({
          'email' : forgot_pass_email,
          'new_password' : resetPassNew,
          'otp' : resetPassOTP
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        }
      }).then(res => res.json())
        .then((json) => {
                //console.log(json); 
                if(json.status === 'success'){
                     setResetPassSuccess(true);
                     setResetPassSubmit(false);
                }else{
                   setResetPassSubmit(false);
                   setResetPassFailure(true);
                }
      });  
  }

  const resendEmailVerification = () => {
    fetch('https://oneclickhuman.com/oneclickprice/resend_email_verification', {
       mode:'cors', 
       method: 'POST',
       body: JSON.stringify({
         'email' : email
       }),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       }
     }).then(res => res.json())
       .then((json) => {
          if(json.status === 'success'){
               alert('Verification Email is resent.');
          }
       });
 }

  return (
    <div id="popup" className={ loginPopup === true ? "login-popup-show" : ""}>
    <div id="login-form">
      { toggle === 'switch to register' ?
        <form onSubmit={handleSubmit}>
          <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
          <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
          <span className="closeLogin" onClick={() => setLoginPopup(false)}><CloseIcon/></span> 
          <div className="inputBox">
            <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
            <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
          </div>
          
          <div className="inputBox">
            <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
          </div>

          <div className="inputBox">
            <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
          </div>

          <button type="submit">SIGN UP</button>
          { registerStatus === 'pending' ?
            <p id="waiting" style={{color: '#fff'}}><img src={wait} alt="submitting"/>Submitting...</p> 
            : ''
          }
          { registerStatus === 'success' ?
            <p id="verfied" style={{color: '#fff'}}>Account activated</p> 
            : ''
          }
          <div className="form-footer">
            <span className="left-foot">All fields are required.</span>
            <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
          </div>
        </form>
        : ''
        }

        { toggle === 'switch to login' ?
         <form onSubmit={handleSubmitLogin}>
          <h3>Welcome Back!</h3>
          <span className="closeLogin" onClick={() => { setLoginPopup(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span>
          <div className="inputBox">
            <input className="form-control" type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
            <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
          </div>
          <div className="inputBox">
             <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
          </div>
          <div id="forgot-pass">
            <span onClick={forgotPassword}>Forgot Password</span> 
          </div>
          <button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
          { loginStatus === 'pending' ?
            <p id="waiting" style={{color: '#fff'}}><img src={wait} alt="submitting"/>Submitting....</p> 
            : ''
          }
          { loginStatus === 'failure' ?
            <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
            : ''
          }
          { loginStatus === 'on-verification' ?
            <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
            : ''
          }
          { loginStatus === 'success' ?
            <p id="verfied" style={{color: '#fff'}}>Login Success</p> 
            : ''
          }
          <div className="form-footer">
            <span className="left-foot">All fields are required.</span>
            <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
          </div>
        </form>
        : ''
      }

      { forgotPass === true ?
        <form onSubmit={sendOTP}>
          <h3>Forgot Password</h3>
          <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>Submit your email id, we will send an otp for resetting new password.</p>
          <span className="closeLogin" onClick={() => {setLoginPopup(false);setForgotPass(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span>
          <div className="inputBox">
            <input type="text" placeholder="Enter your email" onChange={(e) => setForgotPassEmail(e.target.value)} />
          </div>
          <button type="submit" style={{background: '#9b5f07'}}>SEND OTP</button>
          { forgot_pass_submit === true ?
            <p id="waiting" style={{color: '#fff'}}><img src={wait} alt="sending"/>Sending OTP....</p> 
            : '' 
          }
          <div className="form-footer">
            <span className="left-foot">All fields are required.</span>
            <span className="right-foot" onClick={() => {setToggle('switch to register');setForgotPass(false)}}><AccountCircleIcon/> Sign Up</span> 
          </div>
        </form>
        : ''
      }

      { resetPass === true ?
        <form onSubmit={handleResetPassword}>
          <h3>Reset Password</h3>
          <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>We have sent an OTP to your email id, Please enter otp and set new password</p>
          <span className="closeLogin" onClick={() => {setLoginPopup(false);setResetPass(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span>
          <div className="inputBox">
             <input type="text" placeholder="Enter OTP" onChange={(e) => setResetPassOTP(e.target.value)} />
          </div>
          <div className="inputBox">
             <input type="password" placeholder="Enter New Password" onChange={(e) => setResetPassNew(e.target.value)} />
          </div>
          <div className="inputBox">
             <input type="text" placeholder="Confirm New Password" onChange={(e) => setResetPassConfirm(e.target.value)} />
          </div>
          <button type="submit" style={{background: '#9b5f07'}}>SUBMIT</button>
          { reset_pass_submit === true ?
            <p id="waiting" style={{color: '#fff'}}><img src={wait} alt="submitting"/>Submitting....</p> 
            : '' 
          }
          { resetPassSuccess === true ?
            <p style={{color: '#5bed61', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>New Password set Successfully, <span style={{cursor: 'pointer', }} onClick={() => {setResetPass(false);setToggle('switch to login'); }}>Login</span></p>  
            : ''  
          }
          { resetPassFailure === true ?
            <p style={{color: 'red', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>Invalid OTP, try again</p>    
            : ''
          }
        </form> 
        : ''
      }
    </div>
 </div>
  );
};

export default LoginPopup;

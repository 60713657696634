export const Buttons = ({currentStep, handleNext, handlePrev, handlePublish, steps}) => {
    console.log('button render');
    if (currentStep === 1) {
      return <button className="next" onClick={handleNext}>Next</button>;
    } else if (currentStep === steps) {
      return (
          <button className="submit" onClick={handlePublish}>Publish</button>
      );
    } else {
      return (
        <>
          <button className="prev" onClick={handlePrev}>Previous</button>
          <button className="next" onClick={handleNext}>Next</button>
        </>
      );
    }
  };
import wait from "./images/wait.svg";
import successImg from "./images/success.png";
import React, { useState, useEffect} from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Heading from "../components/Heading";
import { loginUser } from "../slices/authSlice";
import MultiStepForm from "../components/MultiStepForm/MultiStepForm";


function Home({width, loginStatus, setLoginPopup}) {

   useEffect(() => {
     // Sidebar toggle with plain javascript
     let toggleBtn = document.querySelector('.toggleBtn');
     let container = document.querySelector('.container-des');
     if(toggleBtn !== null){
        toggleBtn.onclick = function(){
          container.classList.toggle('active');
       }
     }
    }, []);


   const [is_india, setIsIndia] = useState(false);
   const fetchGeoLocation = () => { 
     const apiKey = 'AIzaSyARPhrTVh6j2Bu5TbdpPUTX0KuQNat2GSQ';
     const geoUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`;
     const requestData = {
        homeMobileCountryCode: 310,
        homeMobileNetworkCode: 410,
        radioType: 'gsm',
        carrier: 'Vodafone',
        considerIp: true
     };

     fetch(geoUrl, {
       method: 'POST',
       headers: {
        'Content-Type': 'application/json'
       },
       body: JSON.stringify(requestData)
     })
      .then(response => response.json())
      .then(data => {
         const { lat, lng } = data.location;
         const geocodeApiKey = apiKey;
         const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodeApiKey}`;
  
         return fetch(geocodeUrl);
      })
      .then(response => response.json())
      .then(data => {
         const addressComponents = data.results[0].address_components;
         const country = addressComponents.find(component => component.types.includes('country'));
         if (country) {
           if(country.short_name === 'IN'){
               setIsIndia(true);
            }
         } else {
             console.log('Country information not found');
         }
       })
        .catch(error => {
           console.error('Error:', error);
       }); 
   }

   return (
       <div className="page-wrapper">
         <div className="page-content">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <Heading/>
                <div className="card border-top border-0 border-4 border-white">
                  <div className="card-body p-0">
                    <div id={ width > 768 ? 'tool' : 'tool-mobile' }>
                      { loginStatus === 'success' && <MultiStepForm/> }
                      { loginStatus !== 'success' && 
                        <p id="tool-intro">The world's first Guest Posting Bot built with AI, <br/>
                           no need to write articles or wait for approval. <br/>
                           Get instant approval and build your business now.<br/>
                           <button onClick={() => setLoginPopup(true)}>Login</button>
                        </p> 
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   )

}

export default Home;

import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export default function UnderConstruction(){
    
    const [underConstruction, setUnderConstruction] = useState(false);

    return(
        <div id="pro-popup" className={ underConstruction === true ? "open-pro" : ""}>
        <div id="pro-popup-main">
          <div id="pro-popup-inner">
            <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance. We are working hard to improve our services and will be back in few hours.
            <br/><br/>
             Thank you for your patience and understanding.</p> 
           </div>
           <span id="pro-close" onClick={() => setUnderConstruction(false)}><CloseIcon/></span>
        </div>
       </div>
    )
}